/* eslint-disable @typescript-eslint/no-empty-function */
import * as Auth from "firebase/auth";
import {
  Contact,
  PartnerInfo,
  PartnerType,
  UserAccess,
  eCurrency,
  ePermissionIds,
  eUserRole,
  isEuropeanCountry
} from "@loadsure/core";
import { IJwtInfo } from "../stores/auth/auth.interface";
import {
  Ref,
  computed,
  inject,
  provide,
  reactive,
  ref,
  watch
} from "@nuxtjs/composition-api";
import { iDoc, iLoadingDocs, logger } from "@loadsure/utils";
import firebaseApp from "../plugins/firebase";
import jwt from "jsonwebtoken";
import useAuthStore from "../stores/auth/auth.store";
import usePermissions from "./usePermissions";

export interface iUser {
  displayName: string;
  email: string;
  photoUrl: string;
}

export function useCurrentUserProvide(context) {
  const {
    $axios,
    $config: { apiUrl },
    $sentry,
    route,
    redirect
  } = context;

  const authStore = useAuthStore();

  // eslint-disable-next-line no-undef
  const firebaseAuthUser: Ref<Auth.User | undefined> = ref();
  const userAccess: Ref<UserAccess | undefined> = ref();
  const partnerInfo: Ref<PartnerInfo | undefined> = ref();
  const userPolicies: Ref<any | undefined> = ref();
  const loginInProgress = ref(false);
  const hasLoadsureAccess = ref(false);

  const doApiCall = async (
    // eslint-disable-next-line no-undef
    authUser: Auth.User,
    path: string,
    data?: any
  ) => {
    const userToken = await authUser.getIdToken(true);
    const url = `${apiUrl}/api/${path}`;
    const decodedToken = jwt.decode(`${userToken}`, {
      complete: true
    });
    if (decodedToken?.payload) {
      authStore.setJwtInfo(decodedToken.payload as IJwtInfo);
    }

    if (data) {
      return $axios.$post(url, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
    }
    return $axios.$get(url, {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
  };

  function clearUser() {
    firebaseAuthUser.value = undefined;
    userAccess.value = undefined;
    partnerInfo.value = undefined;
    userPolicies.value = undefined;
    authStore.resetAuthState();
  }

  const setUserDataResponse = async (response: any) => {
    userAccess.value = UserAccess.fromObj(response.userAccess ?? {});
    userPolicies.value = response.userPolicies ?? undefined;
    partnerInfo.value = response.partnerInfo
      ? PartnerInfo.fromObj(response.partnerInfo)
      : undefined;

    await firebaseAuthUser.value?.getIdToken(true);
    /* const message = {
      type: "TOKEN",
      token: await firebaseAuthUser.value?.getIdToken(true)
    };
    window.postMessage(message, "http://localhost:5001"); // Correct target origin */
  };

  const login = async (
    // eslint-disable-next-line no-undef
    authUser?: Auth.User,
    loginPartnerId?: string
  ) => {
    loginInProgress.value = true;
    if (authUser?.email) {
      const postData = loginPartnerId ? { partnerId: loginPartnerId } : {};
      const response = await doApiCall(authUser, "user/login", postData);
      const userToken = await authUser.getIdToken(true);
      const decodedAuth = jwt.decode(`${userToken}`, {
        complete: true
      });
      if (
        response?.userAccess &&
        response?.partnerInfo &&
        decodedAuth?.payload
      ) {
        authStore.setAuthState(
          response.userAccess,
          response.partnerInfo,
          response.userPolicies ?? {},
          decodedAuth.payload as IJwtInfo
        );
      }
      if (response?.success) {
        firebaseAuthUser.value = authUser;
        userPolicies.value = response.userPolicies;
        if (response.userAccess?.active) {
          await setUserDataResponse(response);
        }
        loginInProgress.value = false;
        hasLoadsureAccess.value = response.hasLoadsureAccess;
        return true;
      }
    }
    clearUser();
    loginInProgress.value = false;
    return false;
  };

  const setActivePartnerId = async (partnerId = "", doRedirect = false) => {
    loginInProgress.value = true;
    if (firebaseAuthUser.value) {
      const response = await doApiCall(
        firebaseAuthUser.value,
        "user/changePartner",
        {
          partnerId
        }
      );

      if (response?.success) {
        await setUserDataResponse(response);
        if (doRedirect && route.value.path !== "/dashboard") {
          redirect("/dashboard");
        }
        loginInProgress.value = false;
        return true;
      }
    }
    loginInProgress.value = false;
    return false;
  };

  const $fireAuth = Auth.getAuth(firebaseApp);

  const logout = async () => {
    await setActivePartnerId();
    await Auth.signOut($fireAuth);
    clearUser();
  };

  const refrestUserInfo = async (partnerId: string) => {
    if (firebaseAuthUser.value) {
      const response = await doApiCall(
        firebaseAuthUser.value,
        "user/changePartner",
        {
          partnerId
        }
      );

      if (response?.success) {
        await setUserDataResponse(response);
      }
    }
  };

  provide("setActivePartnerId", setActivePartnerId);
  provide("firebaseAuthUser", firebaseAuthUser);
  provide("userAccess", userAccess);
  provide("userPolicies", userPolicies);
  provide("partnerInfo", partnerInfo);
  provide("refrestUserInfo", refrestUserInfo);
  provide("clearUser", clearUser);
  provide("doApiCall", doApiCall);
  provide("hasLoadsureAccess", hasLoadsureAccess);

  type partnerListTypes = "all" | "recent";

  const getPartners = (type: partnerListTypes): iLoadingDocs<string> => {
    const data = reactive({
      loading: false,
      docs: [] as iDoc<string>[]
    });
    watch(
      userAccess,
      async () => {
        data.loading = true;
        if (firebaseAuthUser.value) {
          try {
            const response = await doApiCall(
              firebaseAuthUser.value,
              `user/partners/${type}`
            );

            if (response?.success) {
              data.docs = response.partners;
            }
          } catch (error: any) {
            $sentry?.captureException(error);
            data.docs = [];
          } finally {
            data.loading = false;
          }
        }
      },
      { immediate: true }
    );
    return data;
  };

  const allPartners = getPartners("all");
  provide("allPartners", allPartners);

  const recentPartners = getPartners("recent");
  provide("recentPartners", recentPartners);

  const partnerId = computed(() => userAccess.value?.partnerId);
  provide("partnerId", partnerId);

  const userId = computed(
    () => userAccess.value?.userId ?? firebaseAuthUser.value?.email
  );
  provide("userId", userId);

  const loggedEmail = computed(
    () => firebaseAuthUser.value?.email ?? undefined
  );

  return {
    login,
    loginInProgress,
    loggedEmail,
    authUser: firebaseAuthUser,
    partnerId,
    userId,
    logout,
    userPolicies
  };
}

export function useCurrentUser(context) {
  const { $sentry } = context;

  const $fireAuth = Auth.getAuth(firebaseApp);

  const allPartners = inject("allPartners", {}) as iLoadingDocs<string>;
  const recentPartners = inject("recentPartners", {}) as iLoadingDocs<string>;

  const firebaseAuthUser = inject("firebaseAuthUser", {}) as Ref<
    Auth.User | undefined
  >;

  const userAccess = inject("userAccess", {}) as Ref<UserAccess | undefined>;
  const partnerInfo = inject("partnerInfo", {}) as Ref<PartnerInfo | undefined>;
  const userPolicies = inject("userPolicies", {}) as Ref<any | undefined>;
  const hasLoadsureAccess = inject("hasLoadsureAccess", {}) as Ref;

  const partnerType = computed(() => partnerInfo.value?.partnerType);

  const setActivePartnerId = inject("setActivePartnerId", () => {}) as (
    partnerId?: string,
    doRedirect?: boolean
  ) => Promise<boolean>;

  const clearUser = inject("clearUser", () => {}) as () => void;
  const refrestUserInfo = inject("refrestUserInfo", () => {}) as (
    partnerId: string
  ) => void;

  async function logout() {
    await setActivePartnerId();
    await Auth.signOut($fireAuth);
    clearUser();
  }

  async function completeSignInWithEmail(email: string, url: string) {
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
    // email = window.prompt('Please provide your email for confirmation');

    try {
      window.localStorage.removeItem("emailForSignIn");
      await Auth.signInWithEmailLink($fireAuth, email, url);
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile === null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser
    } catch (error: any) {
      logger.error("Sign in error", error);
      if (error.code === "auth/invalid-action-code") {
        logger.error(
          "Sign in failed. This can happen if the url is malformed, expired, or has already been used"
        );
      } else if (error.code === "auth/invalid-email") {
        throw new Error(
          "Sign in failed. The email supplied does not match the email used to send the link"
        );
      } else {
        $sentry?.captureException(`Sign in failed. ${error}`);
        throw new Error(error.message);
      }
    }
  }

  const contactDetails = computed(() => {
    if (!firebaseAuthUser.value || !firebaseAuthUser.value?.email) {
      return undefined;
    }

    // todo try and load user from contacts
    return new Contact(
      userAccess.value?.userId ?? firebaseAuthUser.value?.email,
      userAccess.value?.name || firebaseAuthUser.value?.displayName || "",
      firebaseAuthUser.value?.email,
      undefined,
      userAccess.value?.phone || firebaseAuthUser.value?.phoneNumber || ""
    );
  });

  const isLoadsure = computed(() => !!userAccess.value?.isLoadsure);
  const isPortal = computed(() => !!userAccess.value?.isPortal);
  const isPartner = computed(() => !!userAccess.value?.isPartner);
  const isActiveUser = computed(() => !!userAccess.value?.active);
  const isAssuredPartner = computed(
    () => isPartner.value && PartnerType.isAssured(partnerType.value)
  );
  const isRetailerPartner = computed(
    () => isPartner.value && PartnerType.isRetailer(partnerType.value)
  );
  const isWholesalerPartner = computed(
    () => isPartner.value && PartnerType.isWholesaler(partnerType.value)
  );
  const isAnyWholesalerPartner = computed(
    () =>
      isPartner.value &&
      PartnerType.isWholesaler(partnerType.value?.split(":")[0])
  );

  const getBreadcrumbs = (
    partnerId: string,
    partnerName: string,
    disablePartnerLink = false
  ) =>
    partnerId !== userAccess.value?.partnerId
      ? [
          {
            text: "Partners",
            href: "/partners"
          },
          {
            text: partnerName,
            href: disablePartnerLink ? undefined : `/partners/${partnerId}`
          }
        ]
      : [];

  const isDev = computed(
    () =>
      !!userAccess.value?.hasRole(eUserRole.DEV) ||
      !!userAccess.value?.hasRole(eUserRole.ADMIN)
  );

  const isLoadsureAdmin = computed(
    () => isLoadsure.value && !!userAccess.value?.hasRole(eUserRole.ADMIN)
  );

  const isLoadsureUnderwriter = computed(
    () => isLoadsure.value && !!userAccess.value?.hasRole(eUserRole.UNDERWRITER)
  );

  const isPortalAdmin = computed(
    () => isPortal.value && !!userAccess.value?.hasRole(eUserRole.ADMIN)
  );

  const isPortalUser = computed(
    () => isPortal.value && !!userAccess.value?.hasRole(eUserRole.USER)
  );

  const isPartnerAdmin = computed(
    () => isPartner.value && !!userAccess.value?.hasRole(eUserRole.ADMIN)
  );

  const isPartnerUser = computed(
    () => isPartner.value && !!userAccess.value?.hasRole(eUserRole.USER)
  );

  const isAssuredPartnerAdmin = computed(
    () => isAssuredPartner.value && !!userAccess.value?.hasRole(eUserRole.ADMIN)
  );

  const isClaimAssessor = computed(() =>
    userAccess.value?.hasRole(eUserRole.CLAIM_ASSESOR)
  );

  const isClaimManager2 = computed(() =>
    userAccess.value?.hasRole(eUserRole.CLAIM_MANAGER_TWO)
  );

  const isAdminOrSuperAdmin = computed(
    () =>
      isLoadsure.value &&
      (!!userAccess.value?.hasRole(eUserRole.ADMIN) ||
        !!userAccess.value?.hasRole(eUserRole.SUPER_USER) ||
        !!userAccess.value?.hasRole(eUserRole.DEV))
  );

  const email = computed(() => firebaseAuthUser.value?.email ?? undefined);
  const partnerId = computed(() => userAccess.value?.partnerId);
  const userId = computed(
    () => userAccess.value?.userId ?? firebaseAuthUser.value?.email
  );
  const isLoggedIn = computed(() => !!userAccess.value);

  const isHierarchyAdmin = (partnerIds: string[]) =>
    userAccess.value?.partnerId &&
    partnerIds.includes(userAccess.value?.partnerId);

  const isAuthorized = ({
    authorizedUsers,
    partnerIds
  }: {
    authorizedUsers: string[];
    partnerIds: string[];
  }) => {
    const { hasAccessByPermissions } = usePermissions();

    const hasSubmitClaimsPermission = hasAccessByPermissions([
      ePermissionIds.SUBMIT_CLAIMS
    ]);

    return !!(
      (email.value && authorizedUsers.map(user => user.toLowerCase()).includes(email.value.toLowerCase())) ||
      isHierarchyAdmin(partnerIds) ||
      hasSubmitClaimsPermission
    );
  };

  const isCurrentPartnerEuropean = computed(
    () => partnerInfo.value?.address.country
          ? isEuropeanCountry(partnerInfo.value?.address.country)
          : false
  );
  
  return {
    completeSignInWithEmail,
    logout,

    setActivePartnerId,
    email,
    partnerId,
    userId,
    isLoggedIn,

    contactDetails,
    userAccess,
    partnerInfo,
    userPolicies,

    authUser: firebaseAuthUser,

    isLoadsure,
    isPortal,
    isPartner,
    isActiveUser,
    isAssuredPartner,
    isRetailerPartner,
    isWholesalerPartner,
    isAnyWholesalerPartner,
    isLoadsureAdmin,
    hasLoadsureAccess,
    isLoadsureUnderwriter,
    isPortalAdmin,
    isPortalUser,
    isPartnerAdmin,
    isPartnerUser,
    isAssuredPartnerAdmin,
    isClaimAssessor,
    isClaimManager2,
    isDev,
    isAuthorized,
    isHierarchyAdmin,
    isAdminOrSuperAdmin,

    hasNoPartnerAccess: computed(() => !!userAccess.value?.hasNoPartnerAccess),

    partnerCurrency: computed(
      () => partnerInfo?.value?.currency ?? eCurrency.USD
    ),

    canAccessIntegration: computed(() => userAccess.value?.isPartner),

    partnerType,
    allPartners,
    recentPartners,
    isCurrentPartnerEuropean,

    isUserAdmin: computed(() => !!userAccess.value?.hasRole(eUserRole.ADMIN)),

    getBreadcrumbs,
    refrestUserInfo
  };
}
